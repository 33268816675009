<template>
  <div>
    <div class="xpress">
      <CreateAddress
        :visible="isAddressVisible"
        :user="user"
        :establishment="establishment"
        v-on:selectAddress="selectAddress"
      />
    </div>
  </div>
</template>
<script>
import CreateAddress from "@/components/Xpress/CreateAddress";
export default {
  name: "XpressAddress",
  components: {
    CreateAddress,
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    user() {
      return this.$route.params.user;
    },
    establishment() {
      return this.$route.params.eid;
    },
    isAddressVisible() {
      return !this.$store.getters.isNewOrder && this.visible;
    },
  },
  methods: {
    selectAddress(fee, value = "XPRESS") {
      this.$router.push({
        name: "XpressMenu",
        params: { orderType: value, fee: fee },
      });
    },
  },
};
</script>
