<template>
  <div>
    <a-form-item label="Dirección" class="user-data">
      <a-row type="flex">
        <a-input
          v-decorator="[
            'coordinates',
            {
              initialValue: '',
            },
          ]"
        />
      </a-row>
    </a-form-item>
    <a-row type="flex">
      <div class="btn-search-address" @click="addMarker">
        <span>Buscar</span>
      </div>
    </a-row>
    <gmap-map
      id="map"
      :center="center"
      :zoom="17"
      :options="mapOptions"
      style="width: 100%; height: 300px"
      @click="mapClick"
    >
      <gmap-marker
        v-if="markers"
        :position="markers.position"
        :draggable="true"
        @click="center = markers.position"
        @dragend="mapClick"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
const suburb = 1;

export default {
  name: "GoogleMap",
  props: {
    street: null,
    streetNumber: null,
  },
  data() {
    return {
      center: { lat: 0, lng: 0 },
      coordinates: {
        status: false,
        lat: null,
        lng: null,
      },
      geoSuburb: "",
      initialMarker: Object,
      markers: Object,
      mapOptions: {
        disableDefaultUI: true,
      },
      pluscode: {
        status: false,
        value: null,
      },
    };
  },

  mounted() {
    this.geolocate();
  },

  methods: {
    async addMarker() {
      const latLngRegEx =
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
      let totalResults = 0;

      if (latLngRegEx.test(this.street)) {
        this.coordinates.status = true;
        const latLng = this.street.split(",");
        this.coordinates.lat = latLng[0];
        this.coordinates.lng = latLng[1];
      }

      const isSSearch = this.coordinates.status;

      // Replace spaces with %20 and plus sign with %2B
      let address_line = this.street
        .replace(/\s/g, "%20")
        .replace(/\+/g, "%2B");
      let suburb = 1;

      const addressObj = isSSearch
        ? {
            lat: this.coordinates.lat,
            lng: this.coordinates.lng,
          }
        : address_line;

      let response;

      if (isSSearch) {
        response = await this.$store.dispatch("getGoogleApi", {
          location: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${addressObj.lat},${addressObj.lng}&key=${this.$GoogleMapsApiKey}&location_type=ROOFTOP|GEOMETRIC_CENTER`,
        });
      } else {
        response = await this.$store.dispatch("getGoogleApi", {
          location: `https://maps.googleapis.com/maps/api/geocode/json?address=${addressObj}&components=locality:La%20Paz&key=${this.$GoogleMapsApiKey}`,
        });
      }

      if (response.data.status === "OK") {
        const marker = isSSearch
          ? addressObj
          : {
              lng: response.data.results[0].geometry.location.lng,
              lat: response.data.results[0].geometry.location.lat,
            };
        this.geoSuburb =
          response.data.results[0].address_components[suburb].long_name;
        this.markers = { position: marker };
        this.center = marker;

        this.updateCoordinates(
          response.data.results[0].formatted_address,
          response.data.results,
          isSSearch
        );
        totalResults = response.data.results.length;
      } else {
        this.restartControls();
        this.geolocate();
      }

      this.notifyResults(totalResults);

      this.coordinates.status = false;
      this.pluscode.status = false;
    },
    notifyResults(results) {
      this.$notification.info({
        message: "Buscar dirección",
        description: `Se encontraron ${results} resultados`,
        duration: 5,
      });
    },
    geolocate: function () {
      const { lat, lng } = this.$store.getters.isSavedXpressAddress
        ? {
            lat: this.$store.getters.getXpressAddress.address.latitude,
            lng: this.$store.getters.getXpressAddress.address.longitude,
          }
        : this.$store.getters.establishmentLocation;
      this.updateMarker(lat, lng);
      this.updateCoordinates();
    },
    restartControls() {
      this.pluscode = {
        value: null,
      };

      this.coordinates = {
        status: false,
        lat: null,
        lng: null,
      };

      this.$emit("restartControls");
    },
    restartControlsLatLng() {
      this.pluscode.status = false;
      this.restartControls();
    },
    restartControlsPlusCode() {
      this.coordinates.status = false;
      this.restartControls();
    },
    updateCoordinates(address, results, reverse = false) {
      this.$emit(
        "updateCoordinates",
        this.center,
        address,
        this.geoSuburb,
        results,
        reverse
      );
    },
    updateMarker(lat, lng) {
      const marker = { lat, lng };
      this.markers = { position: marker };
      this.center = marker;
    },
    mapClick: async function (event) {
      let totalResults = 0;
      const marker = event.latLng;

      this.initialMarker = marker;
      this.markers = { position: marker };
      this.center = { lat: marker.lat(), lng: marker.lng() };

      const response = await this.getFromLatLng(marker.lat(), marker.lng());

      if (response.data.status === "OK") {
        this.geoSuburb =
          response.data.results[0].address_components[suburb].long_name;

          this.updateCoordinates(
          response.data.results[0].formatted_address,
          response.data.results,
          false
        );
        totalResults = response.data.results.length;
      }
      this.notifyResults(totalResults);

      this.coordinates.status = false;
      this.pluscode.status = false;
    },
    async getFromLatLng(lat, lng) {
      return await this.$store.dispatch("getGoogleApi", { location: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.$GoogleMapsApiKey}&location_type=ROOFTOP|GEOMETRIC_CENTER`} );
    },
    async getLatLngFromPlusCode(value) {
      try {
        return await this.$store.dispatch("get", {
          location: "address/get-from-plus-code",
          params: {
            address: value,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    isPlusCode(code) {
      const SEPARATOR_POSITION_ = 8;
      const SEPARATOR_ = "+";

      const sep = code.indexOf(SEPARATOR_);

      if (code.length == 1) return false;

      // We can have an even number of padding characters before the separator,
      // but then it must be the final character.
      const pad = code.indexOf("0");
      if (pad != -1) {
        // Short codes cannot have padding
        if (sep < SEPARATOR_POSITION_) {
          return false;
        }
        // Not allowed to start with them!
        if (pad == 0) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
